.introduction-arrow-move {
    transition: 0.3s ease-in-out;
}
.introduction-button-div-hover:hover .introduction-arrow-move {
    margin-left: 1.5rem;
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}
.my-masonry-grid_column > div {
    margin-bottom: 30px;
}

.custom-markdown-text-field h1 {
    font-size: 3rem;
    font-weight: bold;
}
.custom-markdown-text-field h2 {
    font-size: 2.5rem;
    font-weight: bold;
}
.custom-markdown-text-field h3 {
    font-size: 2rem;
    font-weight: bold;
}
.custom-markdown-text-field h4 {
    font-size: 1.5rem;
    font-weight: bold;
}
.custom-markdown-text-field h5 {
    font-size: 1rem;
    font-weight: bold;
}
.custom-markdown-text-field h6 {
    font-size: 0.5rem;
    font-weight: bold;
}